<template>
  <div class="vStep">
    <div class="vStep__form">
      <vInput
        id="phone"
        v-model="phone"
        :value="phone"
        placeholder="Телефон"
        :required="true"
        :disabled="true"
        rule="phone"
      />

      <div class="vStep__binding vStep__binding--gray">
        <p>Номер привязан</p>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12.5"
            cy="12"
            r="9.25"
            fill="white"
            stroke="#25C26E"
            stroke-width="1.5"
          />
          <path
            d="M16.0406 10.1001L12.0153 14.1079L9.6001 11.6927"
            stroke="#25C26E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div class="vStep__binding vStep__binding--gray">
        <p>Проверка прошла успешна. Ваша карта активна</p>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12.5"
            cy="12"
            r="9.25"
            fill="white"
            stroke="#25C26E"
            stroke-width="1.5"
          />
          <path
            d="M16.0406 10.1001L12.0153 14.1079L9.6001 11.6927"
            stroke="#25C26E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <vButton
        type="button"
        @handleClick="cardenroll"
      >
        Привязать банковскую карту
      </vButton>
    </div>
    <div class="vStep__info">
      <div class="vStep__description">
        <h2>Шаг 1</h2>
        <p>
          Введите номер телефона, чтобы привязать виртуальную карту официанта
        </p>
      </div>
      <div class="vStep__description--last">
        <div class="vStep__description">
          <h2>Шаг 2</h2>
          <p>Ввведите свои данные. Проверьте корректность введенных данных</p>
        </div>
        <div class="vStep__description">
          <h2>Шаг 3</h2>
          <p>
            При добавлении карты вы будете перенаправлены на сервис банка
            партнёра
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vInput from '@/components/v-input'
import vButton from '@/components/v-button'

export default {
  name: 'VStepOne',
  components: {
    vInput,
    vButton,
  },
  props: {
    token: {
      type: String,
      required: true,
      default: '',
    },
  },
  emits: ['togglePreloader', 'nextStep'],
  data() {
    return {
      phone: '',
    }
  },
  computed: {
    ...mapGetters('virtualCard', ['VIRTUAL_CARD_INFO']),
  },
  mounted() {
    this.phone = this.VIRTUAL_CARD_INFO.data.virtual_card_phone
  },
  methods: {
    ...mapActions('virtualCard', ['CARDENROLL_VIRTUAL_CARD']),
    async cardenroll() {
      this.$emit('togglePreloader')
      const response = await this.CARDENROLL_VIRTUAL_CARD(this.token)
      if (response) {
        this.$emit('nextStep')
      }
      this.$emit('togglePreloader')
    },
  },
}
</script>
