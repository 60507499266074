<template>
  <div class="vStep">
    <div class="vStep__form">
      <div
        v-if="!reg"
        class="vStep__binding vStep__binding--gray"
      >
        <p>Карта выпущена</p>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12.5"
            cy="12"
            r="9.25"
            fill="white"
            stroke="#25C26E"
            stroke-width="1.5"
          />
          <path
            d="M16.0406 10.1001L12.0153 14.1079L9.6001 11.6927"
            stroke="#25C26E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <vInput
        id="phone"
        v-model="phone"
        :value="phone"
        placeholder="Телефон"
        :required="true"
        rule="phone"
      />
      <vButton
        :disabled="phone.length < 17"
        @handleClick="sendCode"
      >
        Привязать карту
      </vButton>
    </div>
    <div class="vStep__info">
      <div
        v-if="!reg"
        class="vStep__description"
      >
        <h2>Шаг 1</h2>
        <p>Выпустите виртуальную карту, чтобы накапливать на ней деньги</p>
      </div>
      <div class="vStep__description">
        <h2>Шаг {{ reg ? 1 : 2 }}</h2>
        <p>
          Введите номер телефона, чтобы привязать виртуальную карту официанта
        </p>
      </div>
    </div>
    <vModalPhoneCode
      v-if="modal"
      :phone="phone"
      @closeModal="toggleModal"
      @checkCode="checkCode"
    />

    <vModal
      v-if="modalError"
      @closeModal="closeModal"
    >
      <template #title>
        <div class="modalError">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 19.25C6.44325 19.25 2.75 15.5568 2.75 11C2.75 6.44325 6.44325 2.75 11 2.75C15.5568 2.75 19.25 6.44325 19.25 11C19.25 15.5568 15.5568 19.25 11 19.25Z"
              stroke="#EC4E4E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.7698 7.33331C10.7041 7.33331 10.6447 7.36107 10.6031 7.40549C10.5646 7.44651 10.5411 7.50175 10.5416 7.56248C10.5416 7.60337 10.5523 7.64177 10.5711 7.67501C10.6104 7.74462 10.6851 7.79165 10.7708 7.79165C10.8564 7.79165 10.9311 7.74462 10.9704 7.67501C10.9892 7.64177 10.9999 7.60337 10.9999 7.56248C10.9999 7.49266 10.9686 7.4301 10.9192 7.38806C10.879 7.35392 10.8269 7.33331 10.7698 7.33331Z"
              fill="#C4C4C4"
            />
            <path
              d="M10.9999 15.5833V11H10.0833M10.9192 7.38806C10.9686 7.4301 10.9999 7.49266 10.9999 7.56248C10.9999 7.60337 10.9892 7.64177 10.9704 7.67501M10.9192 7.38806C10.879 7.35392 10.8269 7.33331 10.7698 7.33331C10.7041 7.33331 10.6447 7.36107 10.6031 7.40549M10.9192 7.38806L10.7698 7.74868L10.6031 7.40549M10.6031 7.40549C10.5646 7.44651 10.5411 7.50175 10.5416 7.56248C10.5416 7.60337 10.5523 7.64177 10.5711 7.67501M10.5711 7.67501C10.6104 7.74462 10.6851 7.79165 10.7708 7.79165C10.8564 7.79165 10.9311 7.74462 10.9704 7.67501M10.5711 7.67501L10.7708 7.56248L10.9704 7.67501"
              stroke="#EC4E4E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Обратитесь в службу поддержки
        </div>
      </template>
      <template #body>
        <p class="modalError__text">
          Вы ввели неправильный код. Обратитесь в службу поддержки
        </p>
        <p class="modalError__text">
          Напишите нам на почту
          <a href="mailto:support@tapper.cloud">support@tapper.cloud</a> и мы с
          вами свяжемся в течение двух дней. <br>
          Укажите тему письма “Неправильный ввод кода”. В письме укажите ваш
          номер телефона и логин в telegram вида @xxxxxxxx.
        </p>
      </template>
    </vModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import vInput from '@/components/v-input'
import vButton from '@/components/v-button'
import vModalPhoneCode from '@/components/modals/v-modal-phone-code'
import vModal from '@/components/modals/v-modal'

export default {
  name: 'VStepOne',
  components: {
    vInput,
    vButton,
    vModalPhoneCode,
    vModal,
  },
  props: {
    token: {
      type: String,
      required: true,
      default: '',
    },
    reg: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['togglePreloader', 'nextStep'],
  data() {
    return {
      phone: '',
      modal: false,
      modalError: false,
    }
  },
  methods: {
    ...mapActions('virtualCard', [
      'VALID_PHONE_VIRTUAL_CARD_INFO',
      'SET_PHONE_SERVICE',
      'FETCH_VIRTUAL_CARD_INFO',
    ]),
    async sendCode() {
      this.$emit('togglePreloader')
      const response = await this.VALID_PHONE_VIRTUAL_CARD_INFO({
        token: this.token,
        phone: this.phone,
      })
      this.$emit('togglePreloader')
      if (response.success) {
        this.toggleModal()
      } else {
        if (
          response.message !== 'Ошибка: Некорректное состояние пользователя B2P'
        ) {
          this.modalError = true
        }
      }
    },
    async checkCode(code) {
      this.$emit('togglePreloader')
      const response = await this.SET_PHONE_SERVICE({
        token: this.token,
        phone: this.phone,
        code: code,
      })
      await this.FETCH_VIRTUAL_CARD_INFO(this.token)
      if (response) {
        this.$emit('nextStep')
      }
      this.$emit('togglePreloader')
    },
    toggleModal() {
      this.modal = !this.modal
    },
    closeModal() {
      this.modalError = false
    },
  },
}
</script>

<style lang="scss" scoped>
.modalError {
  display: flex;
  align-items: center;
  justify-content: center;
  &__text {
    color: #4e5672;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 17px;
    margin-top: 0;
    text-align: left;
    &:last-child {
      margin-bottom: 0;
    }
  }
  svg {
    margin-right: 5px;
  }
  a {
    color: #547aff;
  }
}
</style>
