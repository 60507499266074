
import { defineComponent, PropType } from 'vue'
export default defineComponent ({
  name: 'VCloseButton',
  props: {
    top: {
      type: Number as PropType<number>,
      required: false,
      default: 24
    },
    right: {
      type: Number as PropType<number>,
      required: false,
      default: 16
    },
    noPosition: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false
    }
  },
  emits: ['handleClick'],
  methods: {
    handleClick() {
      this.$emit('handleClick')
    },
  },
})
