<template>
  <div class="vStep">
    <div class="vStep__form">
      <div
        v-if="!reg"
        class="vStep__binding vStep__binding--gray"
      >
        <p>Карта выпущена</p>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12.5"
            cy="12"
            r="9.25"
            fill="white"
            stroke="#25C26E"
            stroke-width="1.5"
          />
          <path
            d="M16.0406 10.1001L12.0153 14.1079L9.6001 11.6927"
            stroke="#25C26E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <vInput
        id="phone"
        v-model="phone"
        :value="phone"
        placeholder="Телефон"
        :required="true"
        :disabled="true"
        rule="phone"
      />

      <div class="vStep__binding vStep__binding--gray">
        <p>Номер привязан</p>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12.5"
            cy="12"
            r="9.25"
            fill="white"
            stroke="#25C26E"
            stroke-width="1.5"
          />
          <path
            d="M16.0406 10.1001L12.0153 14.1079L9.6001 11.6927"
            stroke="#25C26E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div class="vStep__binding vStep__binding--noBorder vStep__binding--gray">
        <p>Данные на проверке</p>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.55691 3.68733C13.1479 1.78533 18.4109 3.96633 20.3129 8.55733C22.2149 13.1483 20.0339 18.4113 15.4429 20.3133C10.8519 22.2153 5.58891 20.0343 3.68691 15.4433C1.78591 10.8523 3.96591 5.58933 8.55691 3.68733Z"
            fill="white"
          />
          <path
            d="M8.55691 3.68733C13.1479 1.78533 18.4109 3.96633 20.3129 8.55733C22.2149 13.1483 20.0339 18.4113 15.4429 20.3133C10.8519 22.2153 5.58891 20.0343 3.68691 15.4433C1.78591 10.8523 3.96591 5.58933 8.55691 3.68733"
            stroke="#413DFF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.7178 7.98532V12.6363L15.3738 14.8653"
            stroke="#413DFF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <vButton
        type="button"
        @handleClick="updateStatus"
      >
        Обновить статус заявки
      </vButton>
      <vButton
        type="button"
        @handleClick="$emit('prevStep')"
      >
        Редактировать данные
      </vButton>
    </div>
    <div class="vStep__info">
      <div
        v-if="!reg"
        class="vStep__description"
      >
        <h2>Шаг 1</h2>
        <p>Выпустите виртуальную карту, чтобы накапливать на ней деньги</p>
      </div>
      <div class="vStep__description">
        <h2>Шаг {{ reg ? 1 : 2 }}</h2>
        <p>
          Введите номер телефона, чтобы привязать виртуальную карту официанта
        </p>
      </div>
      <br v-if="!reg">
      <div class="vStep__description">
        <h2>Шаг {{ reg ? 2 : 3 }}</h2>
        <p>Ввведите свои данные. Проверьте корректность введенных данных</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vInput from '@/components/v-input'
import vButton from '@/components/v-button'

export default {
  name: 'VStepOne',
  components: {
    vInput,
    vButton,
  },
  props: {
    token: {
      type: String,
      required: true,
      default: '',
    },
    reg: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['togglePreloader', 'nextStep', 'prevStep'],
  data() {
    return {
      phone: '',
    }
  },
  computed: {
    ...mapGetters('virtualCard', ['VIRTUAL_CARD_INFO']),
  },
  mounted() {
    this.phone = this.VIRTUAL_CARD_INFO.data.virtual_card_phone
  },
  methods: {
    ...mapActions('virtualCard', ['UPDATE_STATUS']),
    async updateStatus() {
      this.$emit('togglePreloader')
      const respone = await this.UPDATE_STATUS(this.token)
      if (respone) {
        this.$emit('nextStep')
      }
      this.$emit('togglePreloader')
    },
  },
}
</script>
