<template>
  <div class="vStep">
    <div class="vStep__form">
      <div class="vStep__border-bottom">
        <div class="vStep__output">
          <input
            v-model="summ"
            type="number"
            placeholder="Введите сумму для вывода"
            :disabled="VIRTUAL_CARD_BALANCE && VIRTUAL_CARD_BALANCE < 1"
          >
          <div
            class="vStep__output-btn"
            :class="{ 'vStep__output-btn--disabled': VIRTUAL_CARD_BALANCE < 1 }"
            @click="allSumm"
          >
            Вывести всё
          </div>
        </div>
        <vButton
          :disabled="VIRTUAL_CARD_BALANCE < 1 || !summ || summ < 1"
          @handleClick="toggleModal"
        >
          Вывести средства
        </vButton>
      </div>

      <Form
        v-slot="{ errors }"
        class="section-profile__form"
        @submit="checkForm"
      >
        <vInput
          id="phone"
          v-model="phone"
          :value="phone"
          placeholder="Телефон"
          :required="true"
          :disabled="true"
        />
        <div v-if="Object.keys(errors).length > 0">
          {{ errors }}
        </div>
      </Form>
      <div class="vStep__binding vStep__binding--gray">
        <p>Номер привязан</p>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12.5"
            cy="12"
            r="9.25"
            fill="white"
            stroke="#25C26E"
            stroke-width="1.5"
          />
          <path
            d="M16.0406 10.1001L12.0153 14.1079L9.6001 11.6927"
            stroke="#25C26E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div class="vStep__binding vStep__binding--gray">
        <p>Проверка прошла успешна. Ваша карта активна</p>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12.5"
            cy="12"
            r="9.25"
            fill="white"
            stroke="#25C26E"
            stroke-width="1.5"
          />
          <path
            d="M16.0406 10.1001L12.0153 14.1079L9.6001 11.6927"
            stroke="#25C26E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div
        class="vStep__binding vStep__binding--gray vStep__binding--noBorder"
        style="margin-bottom: 0"
      >
        <p>
          Карта привязана
          {{
            VIRTUAL_CARD_INFO.data.virtual_card_pan &&
              VIRTUAL_CARD_INFO.data.virtual_card_pan.substr(-6)
          }}
        </p>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12.5"
            cy="12"
            r="9.25"
            fill="white"
            stroke="#25C26E"
            stroke-width="1.5"
          />
          <path
            d="M16.0406 10.1001L12.0153 14.1079L9.6001 11.6927"
            stroke="#25C26E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div class="vStep__info vStep__info--padding">
      <div class="vStep__description">
        <h2>Телефон</h2>
        <p>Ваш номер телефона, привязанный к виртуальной карте</p>
      </div>
      <div class="vStep__description vStep__description--last">
        <h2>Банковская карта</h2>
        <p>Карта на которую вы можете вывести средства с виртуального счёта</p>
      </div>
    </div>
    <vModalOutput
      v-if="modalOutput"
      :summ="summ"
      :token="token"
      @toggleModal="toggleModal"
      @payout="payout"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Form } from 'vee-validate'
import vInput from '@/components/v-input'
import vButton from '@/components/v-button'
import vModalOutput from '@/components/modals/v-modal-output'
export default {
  name: 'VStepOne',
  components: {
    vInput,
    vButton,
    vModalOutput,
    Form,
  },
  props: {
    token: {
      type: String,
      required: true,
      default: '',
    },
  },
  emits: ['togglePreloader'],
  data() {
    return {
      phone: '',
      summ: null,
      modalOutput: false,
    }
  },
  computed: {
    ...mapGetters('virtualCard', ['VIRTUAL_CARD_INFO', 'VIRTUAL_CARD_BALANCE']),
  },
  mounted() {
    this.phone = this.VIRTUAL_CARD_INFO.data.virtual_card_phone
  },
  methods: {
    ...mapActions('virtualCard', [
      'GET_BALANCE_VIRTUAL_CARD',
      'PAYOUT_VIRTUAL_CARD',
    ]),
    async payout(summ) {
      this.$emit('togglePreloader')
      await this.PAYOUT_VIRTUAL_CARD({ token: this.token, amount: summ })
      this.summ = null
      this.$emit('togglePreloader')
    },
    allSumm() {
      if (this.VIRTUAL_CARD_BALANCE > 0) {
        this.summ = this.VIRTUAL_CARD_BALANCE
      }
    },
    toggleModal() {
      this.modalOutput = !this.modalOutput
    },
  },
}
</script>
