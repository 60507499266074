<template>
  <div
    class="vModalPhoneCode"
    :class="{ 'vModal--botton': styleBottom }"
  >
    <div
      class="vModal__overlay"
      @click="closeModal"
    />
    <div class="vModalPhoneCode__content">
      <div class="vModalPhoneCode__header">
        <div class="vModalPhoneCode__title">
          <div class="vModalPhoneCode__title__code">
            Введите код
          </div>
        </div>
        <div class="vModalPhoneCode__close">
          <vCloseButton @handleClick="closeModal" />
        </div>
      </div>
      <div class="vModalPhoneCode__body">
        <div class="vModalPhoneCode__body__info">
          <p>Мы отправили код на номер &nbsp; {{ phone }}</p>
          <span />
        </div>
        <div class="vModalPhoneCode__body__input">
          <vInput
            id="code"
            v-model="code"
            type="number"
            :value="code"
            placeholder="Код из смс"
          />
        </div>
      </div>
      <div class="vModalPhoneCode__footer">
        <div class="vModalPhoneCode__footer__button">
          <vButton
            v-if="!ERROR_PHONE_CODE"
            :disabled="code.length < 4"
            @handleClick="checkCode"
          >
            Отправить код
          </vButton>
          <vButton v-else>
            Отправить код повторно
          </vButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vCloseButton from '@/components/v-close-button'
import vInput from '@/components/v-input'
import vButton from '@/components/v-button'
export default {
  name: 'VModalPhoneCode',
  components: {
    vCloseButton,
    vInput,
    vButton,
  },
  props: {
    phone: {
      type: String,
      required: true,
      default: '+78008008080',
    },
    styleBottom: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  emits: ['closeModal', 'checkCode'],
  data() {
    return {
      code: '',
    }
  },
  computed: {
    ...mapGetters('virtualCard', ['ERROR_PHONE_CODE']),
  },
  methods: {
    ...mapActions('virtualCard', [
      'FETCH_VIRTUAL_CARD_INFO',
      'SET_PHONE_SERVICE',
    ]),
    closeModal() {
      this.$emit('closeModal')
    },
    checkCode() {
      this.$emit('checkCode', this.code)
      this.$emit('closeModal')
      this.code = ''
    },
  },
}
</script>
<style lang="scss">
.vModalPhoneCode {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  &-bottom {
    @media (max-width: 769px) {
      padding-bottom: 37px;
      align-items: flex-end;
    }
  }
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #dcdee3;
    opacity: 0.9;
    z-index: 1;
  }
  &__content {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 375px;
    min-height: 254px;
    background-color: #ffffff;
    border-radius: 12px;
    font-family: 'Commissioner';
    color: #010d35;
    text-align: center;
    padding: 20px 16px;
    @media (max-width: 769px) {
      width: calc(100% - 32px);
    }
  }
  &__title {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
  }
  &__close {
    .vCloseButton {
      background: #f7f8fa !important;
      border: 1px solid #edeef1 !important;
      &:hover {
        background: #c01718 !important;
      }
    }
  }
  p {
    margin-bottom: 50px;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
  }
  &__body {
    margin-bottom: 16px;
    &__info {
      p {
        font-size: 13px;
        color: #4e5672;
        text-align: left;
        margin-bottom: 16px;
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    &__button {
      width: 100%;
      margin-left: 0;
      button {
        width: 100% !important;
        margin-left: 0;
      }
    }
  }
}
</style>
