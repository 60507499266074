import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["vCloseButton", { noPosition: _ctx.noPosition }]),
    style: _normalizeStyle({ top: _ctx.top + 'px', right: _ctx.right + 'px' }),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, null, 6))
}