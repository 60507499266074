<template>
  <div class="vStep">
    <div class="vStep__form">
      <Form
        v-slot="{ errors }"
        class="section-profile__form"
        @submit="checkForm"
      >
        <div
          v-if="!reg"
          class="vStep__binding vStep__binding--gray"
        >
          <p>Карта выпущена</p>
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="12.5"
              cy="12"
              r="9.25"
              fill="white"
              stroke="#25C26E"
              stroke-width="1.5"
            />
            <path
              d="M16.0406 10.1001L12.0153 14.1079L9.6001 11.6927"
              stroke="#25C26E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <vInput
          id="phone"
          v-model="phone"
          :value="phone"
          placeholder="Телефон"
          :required="true"
          :disabled="true"
          rule="phone"
        />
        <div class="vStep__binding vStep__binding--gray">
          <p>Номер привязан</p>
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="12.5"
              cy="12"
              r="9.25"
              fill="white"
              stroke="#25C26E"
              stroke-width="1.5"
            />
            <path
              d="M16.0406 10.1001L12.0153 14.1079L9.6001 11.6927"
              stroke="#25C26E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <vInput
          id="last_name"
          v-model="last_name"
          :value="last_name"
          placeholder="Фамилия"
          :required="true"
          rule="min:2"
        />

        <vInput
          id="first_name"
          v-model="first_name"
          :value="first_name"
          placeholder="Имя"
          :required="true"
          rule="min:2"
        />

        <vInput
          id="patronymic"
          v-model="patronymic"
          :value="patronymic"
          placeholder="Отчество"
          :required="true"
          rule="min:2"
        />
        <div
          class="form-date"
          :class="{ active: birthday }"
        >
          <date-picker
            v-model:value="birthday"
            :lang="langString"
            :disabled-date="disabledAfterToday"
            popup-class="vStepCalendar"
            @change="selectDate"
            @clear="clearDate"
          />
          <div class="form-date__placeholder">
            Дата рождения
            <div class="form-date__star">
              *
            </div>
          </div>
          <div class="form-date__data">
            {{ convertedDate }}
          </div>
        </div>

        <vInput
          id="passport"
          v-model="passport"
          mask="#### ######"
          :value="passport"
          placeholder="Серия и номер паспорта"
          :required="true"
          rule="min:11"
        />

        <div
          v-if="checkRequest"
          class="vStep__description vStep__description--last vStep__description--mobile"
        >
          <h2>
            Обратите внимание
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.55691 3.68733C13.1479 1.78533 18.4109 3.96633 20.3129 8.55733C22.2149 13.1483 20.0339 18.4113 15.4429 20.3133C10.8519 22.2153 5.58891 20.0343 3.68691 15.4433C1.78591 10.8523 3.96591 5.58933 8.55691 3.68733Z"
                fill="white"
              />
              <path
                d="M8.55691 3.68733C13.1479 1.78533 18.4109 3.96633 20.3129 8.55733C22.2149 13.1483 20.0339 18.4113 15.4429 20.3133C10.8519 22.2153 5.58891 20.0343 3.68691 15.4433C1.78591 10.8523 3.96591 5.58933 8.55691 3.68733"
                stroke="url(#paint0_linear_1337_69117)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.95 17.117C12.548 17.117 12.99 16.649 12.99 16.077C12.99 15.505 12.548 15.037 11.95 15.037C11.352 15.037 10.91 15.505 10.91 16.077C10.91 16.649 11.352 17.117 11.95 17.117ZM11.17 13.62C11.17 14.0508 11.5192 14.4 11.95 14.4C12.3808 14.4 12.73 14.0508 12.73 13.62V7.77988C12.73 7.3491 12.3808 6.99988 11.95 6.99988C11.5192 6.99988 11.17 7.3491 11.17 7.77988V13.62Z"
                fill="url(#paint1_linear_1337_69117)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1337_69117"
                  x1="21.0002"
                  y1="3"
                  x2="3.44619"
                  y2="21.4257"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#547AFF" />
                  <stop
                    offset="1"
                    stop-color="#413DFF"
                  />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_1337_69117"
                  x1="12.99"
                  y1="6.99988"
                  x2="8.91638"
                  y2="7.87899"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#547AFF" />
                  <stop
                    offset="1"
                    stop-color="#413DFF"
                  />
                </linearGradient>
              </defs>
            </svg>
          </h2>
          <p>
            Пожалуйста, проверьте корректность введенных данных ещё раз перед
            отправкой
          </p>
        </div>

        <vButton
          type="submit"
          :disabled="
            Object.keys(errors).length > 0 ||
              convertedDate.length < 1 ||
              passport.length < 11 ||
              last_name.length < 2 ||
              patronymic.length < 2 ||
              first_name.length < 2
          "
        >
          Отправить
        </vButton>
      </Form>
    </div>
    <div class="vStep__info">
      <div
        v-if="!reg"
        class="vStep__description"
      >
        <h2>Шаг 1</h2>
        <p>Выпустите виртуальную карту, чтобы накапливать на ней деньги</p>
      </div>
      <div class="vStep__description">
        <h2>Шаг {{ reg ? 1 : 2 }}</h2>
        <p>
          Введите номер телефона, чтобы привязать виртуальную карту официанта
        </p>
      </div>
      <br v-if="!reg">
      <div class="vStep__description">
        <h2>Шаг {{ reg ? 2 : 3 }}</h2>
        <p>Ввведите свои данные. Проверьте корректность введенных данных</p>
      </div>
      <div
        v-if="checkRequest"
        class="vStep__description vStep__description--last"
      >
        <h2>
          Обратите внимание
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.55691 3.68733C13.1479 1.78533 18.4109 3.96633 20.3129 8.55733C22.2149 13.1483 20.0339 18.4113 15.4429 20.3133C10.8519 22.2153 5.58891 20.0343 3.68691 15.4433C1.78591 10.8523 3.96591 5.58933 8.55691 3.68733Z"
              fill="white"
            />
            <path
              d="M8.55691 3.68733C13.1479 1.78533 18.4109 3.96633 20.3129 8.55733C22.2149 13.1483 20.0339 18.4113 15.4429 20.3133C10.8519 22.2153 5.58891 20.0343 3.68691 15.4433C1.78591 10.8523 3.96591 5.58933 8.55691 3.68733"
              stroke="url(#paint0_linear_1337_69117)"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.95 17.117C12.548 17.117 12.99 16.649 12.99 16.077C12.99 15.505 12.548 15.037 11.95 15.037C11.352 15.037 10.91 15.505 10.91 16.077C10.91 16.649 11.352 17.117 11.95 17.117ZM11.17 13.62C11.17 14.0508 11.5192 14.4 11.95 14.4C12.3808 14.4 12.73 14.0508 12.73 13.62V7.77988C12.73 7.3491 12.3808 6.99988 11.95 6.99988C11.5192 6.99988 11.17 7.3491 11.17 7.77988V13.62Z"
              fill="url(#paint1_linear_1337_69117)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1337_69117"
                x1="21.0002"
                y1="3"
                x2="3.44619"
                y2="21.4257"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#547AFF" />
                <stop
                  offset="1"
                  stop-color="#413DFF"
                />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1337_69117"
                x1="12.99"
                y1="6.99988"
                x2="8.91638"
                y2="7.87899"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#547AFF" />
                <stop
                  offset="1"
                  stop-color="#413DFF"
                />
              </linearGradient>
            </defs>
          </svg>
        </h2>
        <p>
          Пожалуйста, проверьте корректность введенных данных ещё раз перед
          отправкой
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import '@/utils/localeDatePicker'
import { Form } from 'vee-validate'
import vInput from '@/components/v-input'
import vButton from '@/components/v-button'
export default {
  name: 'VStepOne',
  components: {
    vInput,
    Form,
    vButton,
    DatePicker,
  },
  props: {
    token: {
      type: String,
      required: true,
      default: '',
    },
    reg: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['togglePreloader', 'nextStep'],
  data() {
    return {
      phone: '',
      last_name: '',
      first_name: '',
      patronymic: '',
      birthday: '',
      passport: '',
      period: null,
      langString: 'ru',
      convertedDate: '',
      checkRequest: false,
    }
  },
  computed: {
    ...mapGetters('virtualCard', ['VIRTUAL_CARD_INFO']),
  },
  mounted() {
    this.phone = this.VIRTUAL_CARD_INFO.data.virtual_card_phone
  },
  methods: {
    ...mapActions('virtualCard', ['MODIFY', 'FETCH_VIRTUAL_CARD_INFO']),
    async checkForm() {
      if (!this.checkRequest) {
        this.checkRequest = true
      } else {
        this.$emit('togglePreloader')
        const phone = this.phone.replace(/[^\+\d]/g, '')
        const pass = this.passport.replace(/[^\+\d]/g, '')
        const req = {
          first_name: this.first_name,
          patronymic: this.patronymic,
          last_name: this.last_name,
          birth_date: this.convertedDate,
          persondoc_number: pass,
        }
        const response = await this.MODIFY({ token: this.token, data: req })
        await this.FETCH_VIRTUAL_CARD_INFO(this.token)
        if (response) {
          this.$emit('nextStep')
        }
        this.$emit('togglePreloader')
      }
    },
    disabledAfterToday(date) {
      const today = new Date()
      const startDate = new Date(
        today.getFullYear() - 14,
        today.getMonth(),
        today.getDate()
      )
      today.setHours(0, 0, 0, 0)
      // return today < date
      return date > startDate
    },
    async selectDate() {
      if (this.birthday) {
        const month =
            '' + (this.birthday.getMonth() + 1) < 10
              ? '0' + (this.birthday.getMonth() + 1)
              : this.birthday.getMonth() + 1,
          day =
            '' + this.birthday.getDate() < 10
              ? '0' + this.birthday.getDate()
              : this.birthday.getDate(),
          year = this.birthday.getFullYear()
        this.convertedDate = `${year}.${month}.${day}`
      } else {
        this.convertedDate = ''
      }
    },
  },
}
</script>

<style lang="scss">
.vStep .section-profile__form {
  padding: 0;
  border: none;
  .mx-datepicker {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    opacity: 0;
    width: 100%;
    input {
      cursor: pointer;
    }
  }
}
.vStepCalendar {
  .mx-calendar-range {
    .mx-calendar:last-child {
      display: none;
    }
  }
  .mx-datepicker-main.mx-datepicker-popup {
    border: 1px solid #e6e7eb;
    border-radius: 12px;
    width: 375px;
    box-shadow: none;
  }
  .mx-btn.mx-btn-text.mx-btn-icon-double-right,
  .mx-btn.mx-btn-text.mx-btn-current-year,
  .mx-btn.mx-btn-text.mx-btn-icon-double-left {
    display: inline-block;
  }

  .mx-calendar.mx-calendar-panel-date {
    width: 100%;
  }
  .mx-calendar-header-label {
    button {
      color: #010d35;
      font-weight: 600;
      font-family: 'Commissioner';
      font-size: 20px;
    }
  }
  .mx-table th {
    color: #010d35;
    font-family: 'Commissioner';
    font-size: 15px;
    font-weight: 400;
  }
  .mx-calendar-content .cell {
    color: #010d35;
    font-family: 'Commissioner';
    font-size: 16px;
    font-weight: 500;
    border-radius: 6px;
    width: 47px;
    height: 36px;
  }
  .mx-table-date .cell.not-current-month {
    color: #80869a;
    font-family: 'Commissioner';
    font-size: 16px;
    font-weight: 500;
  }
  .mx-calendar-content .cell:hover,
  .mx-calendar-content .cell.active,
  .mx-calendar-content .cell.in-range,
  .mx-calendar-content .cell.hover-in-range {
    background: #f8f9fe;
    color: #6764ff;
  }
  .mx-calendar-content .cell.in-range,
  .mx-calendar-content .cell.hover-in-range {
    border-radius: 0;
  }
  .cell.not-current-month.disabled:hover {
    background: none;
    color: #80869a;
  }
  .mx-calendar-content {
    height: auto;
  }
  .cell.in-range ~ .cell.active {
    border-radius: 0 12px 12px 0 !important;
  }
  .mx-calendar-content .cell.disabled {
    color: #ccc !important;
    background: none;
    opacity: 0.5;
  }
  .mx-calendar-content .cell.disabled:hover {
    color: #ccc;
  }
}
.form-date {
  position: relative;
  background: #fff;
  border: 1px solid #e6e7eb;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 20px;
  height: 52px;
  &.active {
    .form-date__placeholder {
      top: 12px;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      z-index: 2;
    }
  }
}

.form-date__placeholder {
  position: absolute;
  top: 50%;
  left: 12px;
  font-weight: 500;
  line-height: 20px;
  font-size: 16px;
  color: #80869a;
  transform: translateY(-50%);
  transition: all 0.3s;
  z-index: 1;
  display: inline-flex;
}
.form-date__star {
  color: #c01718;
  padding-left: 3px;
}
.form-date__data {
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 12px 0 12px;
  font-family: 'PTRootUI';
  line-height: 24px;
  font-weight: 500;
  font-size: 16px;
  color: #010d35;
}
</style>
